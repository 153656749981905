import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Snackbar,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface FormIOForm {
  _id: string;
  title: string;
  path: string;
  created: string;
}

const FormIOTable: React.FC = () => {
  const navigate = useNavigate();
  const [forms, setForms] = useState<FormIOForm[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await fetch(`https://forms.sympler.co/form?page=${page + 1}&limit=${rowsPerPage}&sort=-created`);
        if (!response.ok) {
          throw new Error('Failed to fetch forms');
        }
        const data = await response.json();
        setForms(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchForms();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCopyLink = (form: FormIOForm) => {
    const jsonData = {
      endpoint: "qrdrstlenauhcye",
      formName: form.path,
    };

    const base64Data = btoa(JSON.stringify(jsonData));
    const linkToCopy = `https://chat.sympler.co/?json=${base64Data}`;

    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        setShowCopySuccess(true);
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  const handleFormClick = (formId: string) => {
    navigate(`/sympler/formio/${formId}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Form.io Forms
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Path</TableCell>
              <TableCell>Created</TableCell>
              <TableCell align="right">Link</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forms
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((form) => (
                <TableRow 
                  key={form._id}
                  hover
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }
                  }}
                >
                  <TableCell onClick={() => handleFormClick(form._id)}>{form.title}</TableCell>
                  <TableCell>{form.path}</TableCell>
                  <TableCell>{new Date(form.created).toLocaleDateString()}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Copy Link">
                      <IconButton 
                        size="small" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyLink(form);
                        }}
                      >
                        <ContentCopyIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFormClick(form._id);
                      }}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 16, color: 'action.active' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={forms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Snackbar
        open={showCopySuccess}
        autoHideDuration={2000}
        onClose={() => setShowCopySuccess(false)}
        message="Link copied to clipboard"
      />
    </Box>
  );
};

export default FormIOTable; 