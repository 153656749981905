import React, { useEffect, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, CircularProgress, Box, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';

const Organizations: React.FC = () => {
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/organizations?page=${page + 1}&limit=${rowsPerPage}&filter=${searchTerm}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw=='
        }
      });
      const data = await response.json();
      setOrganizations(data?.rows ?? []);
      setTotalRows(data?.total ?? 0);
      setLoading(false);
    };

    const debounce = setTimeout(() => {
      fetchOrganizations();
    }, 300);

    return () => clearTimeout(debounce);
  }, [page, rowsPerPage, searchTerm]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (org: any) => {
    navigate(`/sympler/organizations/${org.id}`, { state: org });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <Typography variant="h5" component="div" style={{ padding: '16px' }}>
        Organizations
      </Typography>
      <Box px={2} pb={2}>
        <TextField
          fullWidth
          label="Search organizations"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Organization Name</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Deleted</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizations.map((org: any) => (
                  <TableRow key={org.id} hover onClick={() => handleRowClick(org)} style={{ cursor: 'pointer' }}>
                    <TableCell>{org.name}</TableCell>
                    <TableCell align="center">
                      {org.approved ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
                    </TableCell>
                    <TableCell align="center">
                      {org.deleted ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};

export default Organizations;