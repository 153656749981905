import React, { useState } from 'react';
import { 
    TextField, 
    Button, 
    Box, 
    Typography, 
    Tabs, 
    Tab, 
    Alert,
    Paper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from './contexts/AppContext';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface ApiResponse {
    success: boolean;
    message: string;
    token?: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const LoginComponent: React.FC = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAppContext();

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        setError('');
        setSuccessMessage('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });
            
            const data: ApiResponse = await response.json();
            
            if (response.ok && data.token) {
                localStorage.setItem('token', data.token);
                setIsAuthenticated(true);
                navigate('/');
            } else {
                setError(data.message || 'Invalid credentials');
            }
        } catch (err: unknown) {
            console.error('Login error:', err);
            setError('An error occurred during login');
        }
    };

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        // Validate passwords
        if (password.length < 8) {
            setError('Password must be at least 8 characters long');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            const data: ApiResponse = await response.json();

            if (response.ok) {
                setSuccessMessage('Registration successful! Your account will be reviewed and activated shortly. You will be able to login once your account is approved.');
                // Clear form
                setEmail('');
                setPassword('');
                setConfirmPassword('');
            } else {
                setError(data.message || 'Registration failed');
            }
        } catch (err: unknown) {
            console.error('Registration error:', err);
            setError('An error occurred during registration');
        }
    };

    return (
        <Box sx={{ mt: 4, maxWidth: 400, mx: 'auto' }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange} 
                    centered 
                    sx={{ mb: 2 }}
                >
                    <Tab label="Login" />
                    <Tab label="Register" />
                </Tabs>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {successMessage && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {successMessage}
                    </Alert>
                )}

                <TabPanel value={activeTab} index={0}>
                    <Box component="form" onSubmit={handleLogin}>
                        <Typography variant="h5" component="h1" gutterBottom>
                            Login
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            fullWidth 
                            sx={{ mt: 2 }}
                        >
                            Login
                        </Button>
                    </Box>
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    <Box component="form" onSubmit={handleRegister}>
                        <Typography variant="h5" component="h1" gutterBottom>
                            Register
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            helperText="Password must be at least 8 characters long"
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            fullWidth 
                            sx={{ mt: 2 }}
                        >
                            Register
                        </Button>
                    </Box>
                </TabPanel>
            </Paper>
        </Box>
    );
};

export default LoginComponent;