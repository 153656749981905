import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { SymplerPage } from './clients/sympler/pages';
import { 
    AppBar, 
    Toolbar, 
    Typography, 
    Button, 
    Box, 
    IconButton, 
    Tooltip,
    CircularProgress,
    Avatar,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Menu
} from '@mui/material';
import Login from './Login';
import { PrivateRoute } from './components/PrivateRoute';
import { AppProvider, useAppContext } from './contexts/AppContext';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import ToolsHome from './ToolsHome';
import Tools from './Tools';

const AppContent: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated, user, loading } = useAppContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/login');
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: 'rgb(25,25,25)' }}>
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Morph Dev Tools
                    </Typography>
                    {isAuthenticated ? (
                        <>
                            <Tooltip title="Home">
                                <IconButton color="inherit" component={Link} to="/">
                                    <HomeIcon />
                                </IconButton>
                            </Tooltip>
                            
                            {user && (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={user.email}>
                                        <IconButton
                                            onClick={(e) => setAnchorEl(e.currentTarget)}
                                            size="small"
                                        >
                                            <Avatar sx={{ width: 32, height: 32 }}>
                                                {user.email[0].toUpperCase()}
                                            </Avatar>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        <MenuItem onClick={() => {
                                            setAnchorEl(null);
                                            handleLogout();
                                        }}>
                                            <ListItemIcon>
                                                <LogoutIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>Logout</ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Button color="inherit" component={Link} to="/login">
                            Login
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Box mt={2}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<PrivateRoute><ToolsHome /></PrivateRoute>} />
                    <Route path="/tools/*" element={<PrivateRoute><Tools /></PrivateRoute>} />
                    <Route path="/sympler/*" element={<PrivateRoute><SymplerPage /></PrivateRoute>} />
                </Routes>
            </Box>
        </>
    );
};

const App: React.FC = () => {
    return (
        <Router>
            <AppProvider>
                <AppContent />
            </AppProvider>
        </Router>
    );
};

export default App;