import React, { useState } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';

const ConvertWAVToMP3: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tools/media/convertwavtomp3`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setUploadUrl(data.url); // Assuming the response contains a 'url' field
    } else {
      console.error('Upload failed');
    }
  };

  return (
    <Container>
      <Typography variant="h4">Convert WAV to MP3</Typography>
      <Box mt={2}>
        <input type="file" accept=".wav" onChange={handleFileChange} />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleUpload} 
          disabled={!selectedFile}
        >
          Upload
        </Button>
        {uploadUrl && <Typography variant="body1">File available at: {uploadUrl}</Typography>}
      </Box>
    </Container>
  );
};

export default ConvertWAVToMP3;