import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  Snackbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface FormDetails {
  _id: string;
  title: string;
  path: string;
  created: string;
  modified: string;
}

interface FormSubmission {
  _id: string;
  form: string;
  data: Record<string, any>;
  created: string;
  modified: string;
}

const FormDetail: React.FC = () => {
  const { formId } = useParams<{ formId: string }>();
  const [form, setForm] = useState<FormDetails | null>(null);
  const [submissions, setSubmissions] = useState<FormSubmission[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [submissionToDelete, setSubmissionToDelete] = useState<string | null>(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  useEffect(() => {
    const fetchFormAndSubmissions = async () => {
      try {
        setLoading(true);
        
        // Fetch form details
        const formResponse = await fetch(`https://forms.sympler.co/form/${formId}`);
        if (!formResponse.ok) throw new Error('Failed to fetch form details');
        const formData = await formResponse.json();
        setForm(formData);

        // Fetch submissions
        const submissionsResponse = await fetch(
          `https://forms.sympler.co/form/${formId}/submission?limit=${rowsPerPage}&skip=${page * rowsPerPage}`
        );
        if (!submissionsResponse.ok) throw new Error('Failed to fetch submissions');
        const submissionsData = await submissionsResponse.json();
        setSubmissions(submissionsData);

      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchFormAndSubmissions();
  }, [formId, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteClick = (submissionId: string) => {
    setSubmissionToDelete(submissionId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!submissionToDelete) return;

    try {
      const response = await fetch(
        `https://forms.sympler.co/form/${formId}/submission/${submissionToDelete}`,
        { method: 'DELETE' }
      );

      if (!response.ok) throw new Error('Failed to delete submission');

      // Remove the deleted submission from state
      setSubmissions(submissions.filter(sub => sub._id !== submissionToDelete));
      setShowDeleteSuccess(true);
    } catch (err) {
      console.error('Error deleting submission:', err);
    } finally {
      setDeleteDialogOpen(false);
      setSubmissionToDelete(null);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  if (!form) {
    return (
      <Box p={3}>
        <Typography>Form not found</Typography>
      </Box>
    );
  }

  // Get unique keys from all submissions for table headers
  const submissionKeys = submissions.length > 0
    ? Array.from(new Set(submissions.flatMap(sub => Object.keys(sub.data))))
    : [];

  return (
    <Box p={3}>
      {/* Form Details Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {form.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="text.secondary">Path</Typography>
            <Typography variant="body1">{form.path}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="text.secondary">Created</Typography>
            <Typography variant="body1">
              {new Date(form.created).toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Divider sx={{ my: 3 }} />

      {/* Submissions Table Section */}
      <Typography variant="h5" gutterBottom>
        Submissions
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Submission ID</TableCell>
              <TableCell>Date</TableCell>
              {submissionKeys.map(key => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submission) => (
              <TableRow key={submission._id}>
                <TableCell padding="checkbox">
                  <Tooltip title="Delete Submission">
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteClick(submission._id)}
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>{submission._id}</TableCell>
                <TableCell>
                  {new Date(submission.created).toLocaleDateString()}
                </TableCell>
                {submissionKeys.map(key => (
                  <TableCell key={key}>
                    {submission.data[key]?.toString() || ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Submission</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this submission? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={showDeleteSuccess}
        autoHideDuration={3000}
        onClose={() => setShowDeleteSuccess(false)}
        message="Submission deleted successfully"
      />
    </Box>
  );
};

export default FormDetail; 