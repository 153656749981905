import React from 'react';
import { Typography, Link, AppBar, Toolbar, Button, Container, Box, IconButton } from '@mui/material';
import { Routes as Switch, useLocation, useNavigate, Navigate as Redirect, Route } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Organizations from './Organizations';
import OrganizationsDetail from './OrganizationsDetail';
import StudyDetail from './StudyDetail';
import Users from './Users';
import { PrivateRoute } from '../../../components/PrivateRoute';
import BucketDetail from './BucketDetail';
import ChatFuelBots from './ChatFuelBots'; // Import the new ChatFuelBots component
import FormIOTable from './FormIOTable';
import FormDetail from './FormDetail';

export const SymplerPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const showBackButton = location.pathname.split('/').length > 3;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'rgb(228,148,209)' }}>
        <Toolbar>
          {showBackButton && (
            <IconButton edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Sympler Tools
          </Typography>
          <Button color="inherit" component={Link} href="/sympler/organizations">
            Organizations
          </Button>
          <Button color="inherit" component={Link} href="/sympler/users">
            Users
          </Button>
          <Button color="inherit" component={Link} href="/sympler/formio">
            FormIO
          </Button>
          <Button color="inherit" component={Link} href="/sympler/chatfuel-bots"> {/* New route for ChatFuelBots */}
            ChatFuel Bots
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={2}>
          <Switch>
            <Route path="/formio" element={<PrivateRoute><FormIOTable /></PrivateRoute>} />
            <Route path="/formio/:formId" element={<PrivateRoute><FormDetail /></PrivateRoute>} />
            <Route path="/organizations" element={<PrivateRoute><Organizations /></PrivateRoute>} />
            <Route path="/organizations/:id" element={<PrivateRoute><OrganizationsDetail /></PrivateRoute>} />
            <Route path="/organizations/:orgId/studies/:studyId" element={<PrivateRoute><StudyDetail /></PrivateRoute>} />
            <Route path="/organizations/:orgId/studies/:studyId/buckets/:bucketId" element={<PrivateRoute><BucketDetail /></PrivateRoute>} />
            <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
            <Route path="/chatfuel-bots" element={<PrivateRoute><ChatFuelBots /></PrivateRoute>} />
            <Route path="/" element={<Redirect to="/sympler/organizations" />} /> {/* Updated redirect syntax */}
          </Switch>
        </Box>
      </Container>
    </>
  );
}

export default SymplerPage;