import React, { useState } from 'react';
import { 
    Box, 
    Typography, 
    Button, 
    Paper,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import AddIcon from '@mui/icons-material/Add';
import { useAppContext } from '../../../contexts/AppContext';

// Add interface for User type
interface User {
    hasGoogleAuth: boolean;
    // Add other user properties as needed
}

// Add interface for AppContext
interface AppContextType {
    user: User | null;
    loading: boolean;
}

// Component for Google Auth prompt
const GoogleAuthPrompt: React.FC<{ onAuth: () => void }> = ({ onAuth }) => (
    <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        minHeight="50vh"
    >
        <Paper 
            elevation={3} 
            sx={{ 
                p: 4, 
                textAlign: 'center',
                maxWidth: 400,
                width: '100%'
            }}
        >
            <Typography variant="h5" gutterBottom>
                Google Drive Access Required
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                To use this feature, you need to connect your Google Drive account.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                startIcon={<GoogleIcon />}
                onClick={onAuth}
                size="large"
            >
                Connect Google Drive
            </Button>
        </Paper>
    </Box>
);

// Component for the main bot management UI
const BotManagement: React.FC = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [studyName, setStudyName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error';
    }>({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleCreateBot = async () => {
        if (!studyName.trim()) return;
        
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/google/drive/copy`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ studyName })
            });

            if (!response.ok) {
                throw new Error('Failed to create bot');
            }

            const chatFuelResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/chatfuel/clone`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ studyName })
            });
            
            const data = await response.json();
            if (data.success) {
                setToast({
                    open: true,
                    message: 'Bot created successfully!',
                    severity: 'success'
                });
                setIsDialogOpen(false);
                setStudyName('');
            } else {
                throw new Error(data.message || 'Failed to create bot');
            }
        } catch (error) {
            console.error('Failed to create bot:', error);
            setToast({
                open: true,
                message: error instanceof Error ? error.message : 'Failed to create bot',
                severity: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleCreateBot();
        }
    };

    const handleCloseToast = () => {
        setToast(prev => ({ ...prev, open: false }));
    };

    return (
        <Box p={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4">ChatFuel Bots</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setIsDialogOpen(true)}
                >
                    Create New Bot
                </Button>
            </Box>

            {/* Bot list will go here */}
            
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Create New Bot</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Study Name"
                        type="text"
                        fullWidth
                        value={studyName}
                        onChange={(e) => setStudyName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        disabled={isLoading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleCreateBot} 
                        variant="contained" 
                        disabled={!studyName.trim() || isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar 
                open={toast.open} 
                autoHideDuration={6000} 
                onClose={handleCloseToast}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseToast} 
                    severity={toast.severity}
                    sx={{ width: '100%' }}
                >
                    {toast.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

// Main component with proper return type
const ChatFuelBots: React.FC = (): JSX.Element => {
    const { user, loading } = useAppContext() as AppContextType;

    const handleGoogleAuth = async (): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/google/auth`, {
                method: 'GET', // Explicitly specify method
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            const data: { success: boolean; authUrl: string } = await response.json();
            if (data.success && data.authUrl) {
                window.location.href = data.authUrl;
            }
        } catch (error: unknown) {
            console.error('Failed to initiate Google auth:', error);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!user?.hasGoogleAuth) {
        return <GoogleAuthPrompt onAuth={handleGoogleAuth} />;
    }

    return <BotManagement />;
};

export default ChatFuelBots;