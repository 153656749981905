import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    CircularProgress,
    Box,
    Typography,
    TextField,
    InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface User {
    id: number;
    name: string;
    email: string;
}

interface TransferOwnershipDialogProps {
    open: boolean;
    onClose: () => void;
    onTransfer: (userId: number) => void;
    studyId: number;
}

const TransferOwnershipDialog: React.FC<TransferOwnershipDialogProps> = ({
    open,
    onClose,
    onTransfer,
    studyId
}) => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/sympler/users`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                
                const data = await response.json();
                if (data.success) {
                    setUsers(data.users);
                } else {
                    throw new Error(data.message || 'Failed to fetch users');
                }
            } catch (error) {
                setError(error instanceof Error ? error.message : 'Failed to fetch users');
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchUsers();
            setSearchQuery(''); // Reset search when dialog opens
        }
    }, [open]);

    const handleUserSelect = (userId: number) => {
        onTransfer(userId);
    };

    const filteredUsers = users.filter(user => {
        const query = searchQuery.toLowerCase();
        return (
            user.name.toLowerCase().includes(query) ||
            user.email.toLowerCase().includes(query)
        );
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Transfer Bucket Ownership</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box display="flex" justifyContent="center" p={3}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error" align="center">
                        {error}
                    </Typography>
                ) : (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            fullWidth
                            placeholder="Search by name or email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 2 }}
                        />
                        <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
                            {filteredUsers.length === 0 ? (
                                <Typography color="text.secondary" align="center" py={2}>
                                    No users found
                                </Typography>
                            ) : (
                                filteredUsers.map((user) => (
                                    <ListItem key={user.id} disablePadding>
                                        <ListItemButton onClick={() => handleUserSelect(user.id)}>
                                            <ListItemText 
                                                primary={user.name}
                                                secondary={user.email}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            )}
                        </List>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TransferOwnershipDialog; 