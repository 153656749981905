import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BucketRow from '../components/BucketRow';

interface BucketsTableProps {
  studyId: string;
  study: any; // Add this line
}

const BucketsTable: React.FC<BucketsTableProps> = ({ studyId, study }) => { // Update this line
  const navigate = useNavigate();
  const [buckets, setBuckets] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBuckets();
  }, [studyId, page, rowsPerPage]);

  const fetchBuckets = async () => {
    setLoading(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/buckets?studyId=${studyId}&page=${page + 1}&limit=${rowsPerPage}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw=='
      }
    });
    const data = await response.json();
    setBuckets(data?.rows ?? []);
    setTotalRows(data?.total ?? 0);
    setLoading(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSaveBucket = async (updatedBucket: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/buckets/${updatedBucket.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw==',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: updatedBucket.name,
          order: updatedBucket.order,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update bucket');
      }

      // After successful update:
      fetchBuckets();
    } catch (error) {
      console.error('Error updating bucket:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleDeleteBucket = async (bucketId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/buckets/${bucketId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw==',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete bucket');
      }

      // After successful deletion:
      fetchBuckets();
    } catch (error) {
      console.error('Error deleting bucket:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  console.log(study);

  const handleBucketClick = (bucketId: string) => {
    navigate(`/sympler/organizations/${study.org_id}/studies/${studyId}/buckets/${bucketId}`, { 
      state: { bucketId, study } 
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bucket Name</TableCell>
              <TableCell>Order</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buckets.map((bucket: any) => (
              <BucketRow
                key={bucket.id}
                bucket={bucket}
                onSave={handleSaveBucket}
                onDelete={handleDeleteBucket}
                onDetailClick={handleBucketClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default BucketsTable;