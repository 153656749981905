import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface Bucket {
  id: string;
  name: string;
  order: number;
  // Add other bucket properties as needed
}

interface User {
  id: string;
  name: string;
  email: string;
}

interface Post {
  id: string;
  postId: string;
  created_at: string;
}

const BucketDetail: React.FC = () => {
  const { bucketId } = useParams<{ bucketId: string }>();
  const [bucket, setBucket] = useState<Bucket | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBucketDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/buckets/${bucketId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw=='
          }
        });
        const data = await response.json();
        setBucket(data.bucket);
        setUsers(data.users);
        setPosts(data.posts);
      } catch (error) {
        console.error('Error fetching bucket details:', error);
      }
      setLoading(false);
    };

    fetchBucketDetails();
  }, [bucketId]);

  const deletePost = async (postId: string) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/buckets/${bucketId}/posts/${postId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw=='
        }
      });
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const deleteAllPosts = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/buckets/${bucketId}/posts`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw=='
        }
      });
      setPosts([]);
    } catch (error) {
      console.error('Error deleting all posts:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (!bucket) {
    return <Typography variant="h6">Bucket not found</Typography>;
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>{bucket.name}</Typography>
      <Typography variant="body1" gutterBottom>Order: {bucket.order}</Typography>

      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Associated Users</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Posts in this Bucket</Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={deleteAllPosts}
        >
          Delete All Posts
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Post ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post) => (
              <TableRow key={post.id}>
                <TableCell>{post.postId}</TableCell>
                <TableCell>{new Date(post.created_at).toLocaleString()}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => deletePost(post.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BucketDetail;