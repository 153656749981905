import React, { useState } from 'react';
import { TableRow, TableCell, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface BucketRowProps {
  bucket: any;
  onSave: (updatedBucket: any) => void;
  onDelete: (bucketId: string) => void;
  onDetailClick: (bucketId: string) => void;
}

const BucketRow: React.FC<BucketRowProps> = ({ bucket, onSave, onDelete, onDetailClick }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(bucket.name);
  const [editedOrder, setEditedOrder] = useState(bucket.order);

  const handleSave = () => {
    onSave({ ...bucket, name: editedName, order: editedOrder });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedName(bucket.name);
    setEditedOrder(bucket.order);
    setIsEditing(false);
  };

  const handleRowClick = (event: React.MouseEvent) => {
    if (!isEditing) {
      onDetailClick(bucket.id);
    }
  };

  const handleActionClick = (event: React.MouseEvent, action: () => void) => {
    event.stopPropagation();
    action();
  };

  return (
    <TableRow
      onClick={handleRowClick}
      sx={{ cursor: isEditing ? 'default' : 'pointer', '&:hover': { backgroundColor: isEditing ? 'inherit' : 'rgba(0, 0, 0, 0.04)' } }}
    >
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            fullWidth
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          bucket.name
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedOrder}
            onChange={(e) => setEditedOrder(e.target.value)}
            fullWidth
            type="number"
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          bucket.order
        )}
      </TableCell>
      <TableCell align="right">
        {isEditing ? (
          <>
            <IconButton onClick={(e) => handleActionClick(e, handleSave)}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={(e) => handleActionClick(e, handleCancel)}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={(e) => handleActionClick(e, () => setIsEditing(true))}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={(e) => handleActionClick(e, () => onDelete(bucket.id))}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={(e) => handleActionClick(e, () => onDetailClick(bucket.id))}>
              <ArrowForwardIosIcon />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BucketRow;