import React from 'react';
import MediaTools from './Media'; // Import MediaTools
import { PrivateRoute } from '../components/PrivateRoute';
import ConvertWAVToMP3 from './Media/ConvertWAVToMP3';

const Tools: React.FC = () => {
  return (
    <>
      <PrivateRoute children={<MediaTools />} />
      <PrivateRoute children={<ConvertWAVToMP3 />} />
    </>
  );
};

export default Tools;
