import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, CircularProgress, Box, Paper, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

interface StudiesTableProps {
  organizationId: string;
  searchTerm: string;
}

const StudiesTable: React.FC<StudiesTableProps> = ({ organizationId, searchTerm }) => {
  const [studies, setStudies] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudies = async () => {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/sympler/studies?page=${page + 1}&limit=${rowsPerPage}&filter=${searchTerm}&organizationId=${organizationId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'ApiKey': 'U3VwZXJTZWNyZXRNb3JwaERldlRvb2xzUGFzcw=='
        }
      });
      const data = await response.json();
      setStudies(data?.rows ?? []);
      setTotalRows(data?.total ?? 0);
      setLoading(false);
    };

    const debounce = setTimeout(() => {
      fetchStudies();
    }, 300);

    return () => clearTimeout(debounce);
  }, [page, rowsPerPage, searchTerm, organizationId]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (study: any) => {
    navigate(`/sympler/organizations/${organizationId}/studies/${study.id}`, { state: study });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Study Name</TableCell>
              <TableCell align="center">Approved</TableCell>
              <TableCell align="center">Deleted</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studies.map((study: any) => (
              <TableRow key={study.id} hover onClick={() => handleRowClick(study)} style={{ cursor: 'pointer' }}>
                <TableCell>{study.name}</TableCell>
                <TableCell align="center">
                  {study.approved ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
                </TableCell>
                <TableCell align="center">
                  {study.deleted ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
                </TableCell>
                <TableCell align="right">
                  <IconButton>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default StudiesTable;