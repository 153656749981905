import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Define the tools data as a JSON object
const toolsData = [
  { name: 'Convert WAV to MP3', url: '/tools/media/convert-wav-to-mp3' },
  // Add more tools here as needed
];

const MediaTools: React.FC = () => {
  const navigate = useNavigate();

  const handleRowClick = (url: string) => {
    navigate(url);
  };

  return (
    <Container>
      <Box mb={2}>
        <Typography variant="h4" component="h1">Media Tools</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tool</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toolsData.map((tool, index) => (
              <TableRow key={index} hover onClick={() => handleRowClick(tool.url)} style={{ cursor: 'pointer' }}>
                <TableCell>{tool.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default MediaTools;