import React, { createContext, useState, useContext, useEffect } from 'react';

interface User {
    id: number;
    email: string;
    isActive: number;
    oauthToken: string | null;
    oauthRefreshToken: string | null;
    oauthTokenExpiresAt: string | null;
    token: string | null;
    tokenExpiration: string | null;
    hasGoogleAuth: boolean;
}

interface AppContextType {
    isAuthenticated: boolean;
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    loading: boolean;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

export const AppProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!isAuthenticated) {
                setUser(null);
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/me`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    // If token is invalid, clear authentication
                    if (response.status === 401) {
                        setIsAuthenticated(false);
                        localStorage.removeItem('token');
                    }
                    setUser(null);
                } else {
                    const data = await response.json();
                    if (data.success) {
                        setUser(data.user);
                    } else {
                        setUser(null);
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [isAuthenticated]); // Re-fetch when authentication status changes

    return (
        <AppContext.Provider value={{ 
            isAuthenticated, 
            setIsAuthenticated, 
            user, 
            setUser,
            loading 
        }}>
            {children}
        </AppContext.Provider>
    );
};