import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const ToolsHome: React.FC = () => {
  const navigate = useNavigate();

  const tools = [
    { name: 'Sympler', path: '/sympler/organizations' },
    { name: 'Media', path: '/tools/media' }, // Added new tool for Media
    // Add more tools as needed
  ];

  const handleRowClick = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {tools.map((tool, index) => (
              <TableRow key={index} hover onClick={() => handleRowClick(tool.path)} style={{ cursor: 'pointer' }}>
                <TableCell>{tool.name}</TableCell>
                <TableCell align="right"> {/* Added second column for arrow */}
                  <ArrowForwardIosIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Add the link to Hello World */}
      {/* <Button component={Link} to="/hello-world" variant="contained" color="primary">
        Go to Hello World
      </Button> */}
    </>
  );
};

export default ToolsHome;