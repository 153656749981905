import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Paper, Box, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import StudiesTable from './StudiesTable';

const OrganizationsDetail: React.FC = () => {
  const location = useLocation();
  const organization = location.state as any;
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          {organization.name}
        </Typography>
        <Typography>
          Approved: {organization.approved ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
        </Typography>
        <Typography>
          Deleted: {organization.deleted ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
        </Typography>
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Studies
          </Typography>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Search studies"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <StudiesTable organizationId={organization.id} searchTerm={searchTerm} />
        </Box>
      </Box>
    </Paper>
  );
};

export default OrganizationsDetail;