import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Paper, Box, Button, Snackbar, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import BucketsTable from './BucketsTable';
import TransferOwnershipDialog from '../components/TransferOwnershipDialog';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const StudyDetail: React.FC = () => {
  const location = useLocation();
  const study = location.state as any;
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [toast, setToast] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleTransferOwnership = async (userId: number) => {
    setTransferLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients/sympler/buckets/ownership`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            studyId: study.id,
            userId
          })
        }
      );

      const data = await response.json();
      if (data.success) {
        setToast({
          open: true,
          message: 'Ownership transferred successfully',
          severity: 'success'
        });
        setIsTransferDialogOpen(false);
        // Optionally refresh the bucket list or study data here
      } else {
        throw new Error(data.message || 'Failed to transfer ownership');
      }
    } catch (error) {
      setToast({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to transfer ownership',
        severity: 'error'
      });
    } finally {
      setTransferLoading(false);
    }
  };

  const handleCloseToast = () => {
    setToast(prev => ({ ...prev, open: false }));
  };

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          {study.name}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <Typography>
              Approved: {study.approved ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
            </Typography>
            <Typography>
              Deleted: {study.deleted ? <CheckIcon color="success" /> : <ErrorIcon color="error" />}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            startIcon={<PersonAddIcon />}
            onClick={() => setIsTransferDialogOpen(true)}
            disabled={transferLoading}
          >
            Transfer Ownership
          </Button>
        </Box>
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Buckets
          </Typography>
          <BucketsTable studyId={study.id} study={study} />
        </Box>
        <TransferOwnershipDialog
          open={isTransferDialogOpen}
          onClose={() => setIsTransferDialogOpen(false)}
          onTransfer={handleTransferOwnership}
          studyId={study.id}
        />
        <Snackbar 
          open={toast.open} 
          autoHideDuration={6000} 
          onClose={handleCloseToast}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleCloseToast} 
            severity={toast.severity}
            sx={{ width: '100%' }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      </Box>
    </Paper>
  );
};

export default StudyDetail;